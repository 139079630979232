/* App.css */

/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Custom properties */
:root {
  --primary-color: #ff6600;
  --secondary-color: #1c1c1c;
  --text-color: #ffffff;
  --background-color: #c1c1c1;
}

/* Global styles */
body {
  background-color: var(--secondary-color);
  color: var(--text-color);
  font-family: Arial, sans-serif;
}

/* App layout */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

/* Header */
.App-header {
  background-color: var(--primary-color);
  padding: 20px;
  color: var(--text-color);
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Logo */
.App-logo {
  height: 80px;
  pointer-events: none;
  margin-bottom: 20px;
}

/* Main content */
.App-content {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

/* Links */
.App-link {
  color: #61dafb;
  text-decoration: none;
}

.App-link:hover {
  text-decoration: underline;
}

/* Form elements */
input[type="text"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button {
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #cc5500;
}

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #4c4c4c;
  font-weight: bold;
}

th:nth-child(1),
td:nth-child(1) {
  width: 40%;
}

th:nth-child(2),
td:nth-child(2),
th:nth-child(3),
td:nth-child(3) {
  width: 20%;
}
/* Simulation */
.simulate-button {
  position: relative;
  display: inline-block;
}

.simulating {
  animation: pulse 2s infinite;
}

.simulating-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: #fff;
}

.simulating-box {
  display: inline-block;
  padding: 10px;
  background-color: #fb8d61;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
}

.simulating-box.simulating {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

/* Matchup card */
.matchup-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #3c3c3c;
  border: 2px solid var(--primary-color);
  color: var(--text-color);
  border-radius: 4px;
  margin-bottom: 10px;
}

.team-info {
  display: flex;
  align-items: center;
}

.team-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  color: var(--text-color);
}

.team-name {
  font-weight: bold;
  font-family: "March Madness", sans-serif;
}

.team-seed {
  margin-left: 5px;
  color: #888;
}

.vs-text {
  font-weight: bold;
  margin: 0 10px;
}

.winner {
  background-color: #c8e6c9;
}